import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 全局配置axios请求
import axios from 'axios'
import VueAxios from 'vue-axios'
import qs from 'qs'

// 配置vant框架
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import '@/styles/index.scss'


import vResize from 'v-resize'
Vue.use(vResize)
// 安装echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
//配全局属性配置，在任意组件内可以使用this.$qs获取qs对象
Vue.prototype.$qs = qs
Vue.use(VueAxios, axios)
import { Toast } from 'vant';
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.path === '/entryPage' && from.name !== null) {
    next('/homepage');
  } else {
    next();
  }
  Toast.loading({
    message: '加载中...',
    forbidClick: true,
    duration: 0,
  });
});
router.afterEach(() => {
  Toast.clear();
});
Vue.mixin({
  mounted () {
    this.$nextTick(() => {
      Toast.clear();
    });
  },
});
/* 跳转页面 */
Vue.prototype.routeGo = function (path, query = {}) {
  router.push({ path, query });
  store.commit("setType", "slide-left");
  store.commit("setOK", false);
};
/* 返回页面 */
Vue.prototype.routeBack = function () {
  store.commit("setOK", true);
  store.commit("setType", "slide-right");
  router.back();
};


Vue.config.productionTip = false
Vue.use(ElementUI);


Vue.directive('clickOutside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});


// // 获取手机暗色亮色模式
// const app = Vue.createApp(App);
// // 添加全局 mixin
// app.mixin({
//   data () {
//     return {
//       isDarkMode: false,
//     };
//   },
//   mounted () {
//     if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
//       this.isDarkMode = true;
//     }
//     window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
//       this.isDarkMode = e.matches;
//     });
//   },
// });

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
