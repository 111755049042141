import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
Vue.use(Router)
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
const router = new Router({
  mode: "history",
  base: 'ClientApp',
  // 添加 scrollBehavior 方法
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: '/',
      component: resolve => require(['@/views/PageView'], resolve),
      children: [
        {
          path: '/',
          redirect: '/entryPage',
          component: resolve => require(['@/views/entryPage'], resolve),
        },
        // 进入页
        {
          path: '/entryPage',
          name: 'entryPage',
          component: resolve => require(['@/views/entryPage'], resolve),
        },
        // 登录
        {
          path: '/login',
          name: 'login',
          component: resolve => require(['@/views/login'], resolve),
          meta: {
            title: '登录',
          }
        },
        // 隐私协议
        {
          path: '/privacyAgreement',
          name: 'privacyAgreement',
          component: resolve => require(['@/views/privacyAgreement'], resolve),
          meta: {
            title: '隐私协议',
          }
        },
        // 有用户协议
        {
          path: '/userAgreement',
          name: 'userAgreement',
          component: resolve => require(['@/views/userAgreement'], resolve),
          meta: {
            title: '用户协议',
          }
        },
        // 注册
        {
          path: '/register',
          name: 'register',
          component: resolve => require(['@/views/register'], resolve),
          meta: {
            title: '注册',
          }
        },
        // 找回密码
        {
          path: '/retrievePassword',
          name: 'retrievePassword',
          component: resolve => require(['@/views/retrievePassword'], resolve),
          meta: {
            title: '找回密码',
          }
        },
        {
          path: '/homePage',
          name: 'homePage',
          component: resolve => require(['@/views/homePage'], resolve),
          meta: {
            title: '首页',
          }
        },
        // 搜索
        {
          path: '/search',
          name: 'search',
          component: resolve => require(['@/views/search'], resolve),
          meta: {
            title: '搜索',
          }
        },
        // 产品详情
        {
          path: '/productDetails',
          name: 'productDetails',
          component: resolve => require(['@/views/productDetails'], resolve),
          meta: {
            title: '产品详情',
          }
        },
        // 交易记录
        {
          path: '/transactionRecords',
          name: 'transactionRecords',
          component: resolve => require(['@/views/transactionRecords'], resolve),
          meta: {
            title: '交易记录',
          }
        },
        // 购买产品
        {
          path: '/purchasedProduct',
          name: 'purchasedProduct',
          component: resolve => require(['@/views/purchasedProduct'], resolve),
          meta: {
            title: '购买产品',
          }
        },
        // 提现成功
        {
          path: '/flowDetails',
          name: 'flowDetails',
          component: resolve => require(['@/views/flowDetails'], resolve),
          meta: {
            title: '流水详情',
          }
        },
        // 我的收益
        {
          path: '/myBenefits',
          name: 'myBenefits',
          component: resolve => require(['@/views/myBenefits'], resolve),
          meta: {
            title: '我的收益',
          }
        },
        // 收益详情
        {
          path: '/revenueDetails',
          name: 'revenueDetails',
          component: resolve => require(['@/views/revenueDetails'], resolve),
          meta: {
            title: '收益详情',
          }
        },
        // 综合账户
        {
          path: '/omnibusAccount',
          name: 'omnibusAccount',
          component: resolve => require(['@/views/omnibusAccount'], resolve),
          meta: {
            title: '综合账户',
          }
        },
        // 人民币账户
        {
          path: '/rebAccount',
          name: 'rebAccount',
          component: resolve => require(['@/views/rebAccount'], resolve),
          meta: {
            title: '人民币账户',
          }
        },
        // 美元账户
        {
          path: '/usdAccount',
          name: 'usdAccount',
          component: resolve => require(['@/views/usdAccount'], resolve),
          meta: {
            title: '美元账户',
          }
        },
        // 货币兑换
        {
          path: '/currencyExchange',
          name: 'currencyExchange',
          component: resolve => require(['@/views/currencyExchange'], resolve),
          meta: {
            title: '货币兑换',
          }
        },
        {
          path: '/exchangeDetails',
          name: 'exchangeDetails',
          component: resolve => require(['@/views/exchangeDetails'], resolve),
          meta: {
            title: '兑换明细',
          }
        },
        // 银行卡列表
        {
          path: '/bankCardList',
          name: 'bankCardList',
          component: resolve => require(['@/views/bankCardList'], resolve),
          meta: {
            title: '银行卡列表',
          }
        },
        // 添加银行卡
        {
          path: '/addBankcard',
          name: 'addBankcard',
          component: resolve => require(['@/views/addBankcard'], resolve),
          meta: {
            title: '添加银行卡',
          }
        },
        // 设置密码
        {
          path: '/setPassword',
          name: 'setPassword',
          component: resolve => require(['@/views/setPassword'], resolve),
          meta: {
            title: '设置密码',
          }
        },
        // 确认密码
        {
          path: '/confirmPassword',
          name: 'confirmPassword',
          component: resolve => require(['@/views/confirmPassword'], resolve),
          meta: {
            title: '确认密码',
          }
        },
        // 修改交易密码
        {
          path: '/tansactionPassword',
          name: 'tansactionPassword',
          component: resolve => require(['@/views/tansactionPassword'], resolve),
          meta: {
            title: '修改交易密码',
          }
        },
        // 修改手机号-验证原手机号
        {
          path: '/oldPhone',
          name: 'oldPhone',
          component: resolve => require(['@/views/oldPhone'], resolve),
          meta: {
            title: '修改手机号',
          }
        },
        // 修改手机号-验证新手机号
        {
          path: '/newPhone',
          name: 'newPhone',
          component: resolve => require(['@/views/newPhone'], resolve),
          meta: {
            title: '修改手机号',
          }
        },
        // 充值
        {
          path: '/recharge',
          name: 'recharge',
          component: resolve => require(['@/views/recharge'], resolve),
          meta: {
            title: '充值',
          }
        },
        // 充值完成
        {
          path: '/rechargrFinish',
          name: 'rechargrFinish',
          component: resolve => require(['@/views/rechargrFinish'], resolve),
          meta: {
            title: '充值完成',
          }
        },
        // 提现
        {
          path: '/withdrawal',
          name: 'withdrawal',
          component: resolve => require(['@/views/withdrawal'], resolve),
          meta: {
            title: '提现',
          }
        },
        // 提现完成
        {
          path: '/withdrawalFinish',
          name: 'withdrawalFinish',
          component: resolve => require(['@/views/withdrawalFinish'], resolve),
          meta: {
            title: '提现完成',
          }
        },
        // 我的设置
        {
          path: '/mySettings',
          name: 'mySettings',
          component: resolve => require(['@/views/mySettings'], resolve),
          meta: {
            title: '我的设置',
          }
        },
        // 编辑资料
        {
          path: '/editInfo',
          name: 'editInfo',
          component: resolve => require(['@/views/editInfo'], resolve),
          meta: {
            title: '编辑资料',
          }
        },




      ]
    }
  ]
});

export default router;
