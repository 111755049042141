import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
// Vuex 状态管理的 类似本地存储很像
// Vuex 组件之间的通信 不需要考虑组件的关系了
Vue.use(Vuex)

export default new Vuex.Store({
  state: {// 存储的状态管理
    transaction: false,
    // 路由方向管理
    type: "",
    isOk: true,
    routeHistory: [],
    information: null,
    setProductObjectId: null,
    userInfo: null,
    isLogin: false,

  },
  getters: {// 定义方法去对状态管理的值进行处理 类似计算属性
    getType: (state) => state.type,
  },
  mutations: {// 定义方法去修改状态 唯一修改状态的途径
    setTransaction (state, transaction) {
      state.transaction = transaction
    },
    setType: (state, val) => {
      state.type = val;
    },
    setOK: (state, val) => {
      state.isOk = val;
    },
    // 获取用户信息
    setInformation (state, information) {
      state.information = information
    },
    setLogin (state, isLogin) {
      state.isLogin = isLogin
    },
    // 清空用户信息
    clearInformation (state) {
      state.information = null;
    },
    // 设置产品id
    setProductObjectId (state, id) {
      state.setProductObjectId = id;
    },
    // 用户数据信息
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo
    },
  },
  plugins: [createPersistedState()],
  actions: {// 类似mutations 但不能直接去修改状态 也需要mutations的支持
  },
  modules: {// 模块
  }
})
